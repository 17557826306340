import React, {useState} from 'react'
import './header.css'
const Header = ({
    state
}) => {
  
    return (
        <div className='header'>
            <div className='header_img'>
                {
              
                state.status == 'SUCCSSES' ?  <img src="./img/checked_true.png" alt="" />:  <img src="./img/checked_false.png" alt="" />
                 
                }
                
            </div>
            <h1>Verification</h1>
            <p style={state.status == 'SUCCSSES' ? {color: 'green'} : {color: 'red'}}>{state.message}</p>
            <p>Моля поставете кода, който ви е изпратен на e-mail</p>
        </div>
    )
}

export default Header
