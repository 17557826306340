import React, { useState } from 'react';
import Header from '../Header';
import './main.css';

const Main = () => {
    const [state, setState] = useState({ status: "FAILED", message: "НЕПОТВЪРДЕНА РЕГИСТРАЦИЯ!" })

    const onHandlerSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData(e.target);

        let code = formData.get('code');

        const uniqueString = { uniqueString: code };

        fetch('http://eldrive.vrs-bg.com/verified/verified', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(uniqueString),
        })
            .then(response => response.json())
            .then(response => {
                setState(response)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    console.log(state)

    return (
        <>
            <Header state={state}/>
            <div className='main'>
                <form onSubmit={onHandlerSubmit}>
                    <input type="text" name="code" />
                    <button type="submit">VERIFY</button>
                </form>
            </div>
        </>
    )
}

export default Main
